import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Button } from "../../Button"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import { Paragraph } from "../../Text"
import SectionHero from "../SectionHero"

import * as S from "./styled"

const SectionSocialEngagementIntern = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Engajamento Social
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <SectionContent className="s-content">
      <Container>
        <div className="box-content">
          <StaticImage
            className="s-image"
            src="../../../images/engajamento_social.png"
            objectFit="cover"
            objectPosition="left"
            alt="Engajamento Social"
          />

          <Paragraph darken className="paragraph">
            Inicialmente, com a finalidade de prestação de serviços relevantes à
            sociedade, a Ruy Molina Advocacia, entende que faz parte da sua
            função social, implementar ações que promovam o desenvolvimento em
            todo o Brasil, que é o seu território de atuação.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Além de apoiar e ser parceira de vários projetos sociais, a Ruy
            Molina Advocacia presta assessoria jurídica para as associações,
            ONG’s, fundações e entidades parceiras. Bem como promove a
            intermediação entre as associações parceiras com os doadores, como
            empresários, empresas, políticos e entes governamentais. Com
            objetivo de conectar os doadores com as associações e as pessoas que
            necessitam de doações.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Isso porque, a nossa empresa entende que além da preservação dos
            Direitos, as pessoas possuem necessidades imediatas, que inclusive,
            se não atendidas, podem inviabilizar a prestação jurisdicional em
            favor do necessitado.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Desta forma, a Ruy Molina Advocacia é uma empresa totalmente aberta
            ao diálogo com a sociedade, proporcionando a abertura para o lugar
            de fala da sociedade como um todo. Se você faz parte de algum grupo
            vulnerável, é representante de alguma associação, desenvolve algum
            projeto social, ou presta qualquer tipo de serviço à sociedade, nós
            temos interesse em manter contato com você. Te convidamos a conhecer
            nosso projeto e fazer parte dele.
          </Paragraph>
          <Paragraph darken className="paragraph">
            Juntos lutaremos por um mundo mais justo e igualitário.
          </Paragraph>
          <Button
            className="btn"
            as={AniLink}
            theme="secondary"
            to="/trabalhe-conosco"
            cover
            direction="bottom"
            bg="#EAEAEA"
            duration={0.8}
            title="Ver todos os artigos"
          >
            Trabalhe Conosco
          </Button>
        </div>
      </Container>
    </SectionContent>
  </S.Section>
)

export default SectionSocialEngagementIntern
