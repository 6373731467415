import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

import { Section as SectionComponent } from "../"

export const Section = styled(SectionComponent)`
  padding: 0 0 100px;

  .s-content {
    margin-top: 50px;

    .box-content {
      max-width: 900px;
    }

    .s-image {
      height: 380px;
      margin-bottom: 40px;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        height: 320px;
      }
      @media ${breakpoints.lessThan(sizes.tablet)} {
        height: 220px;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        height: 200px;
      }
      @media ${breakpoints.lessThan(sizes.smaller)} {
        height: 160px;
      }
    }

    .paragraph {
      line-height: 1.5;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
`
