import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionSocialEngagementIntern from "../components/Section/SectionSocialEngagementIntern"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const SocialEngagementPage = () => (
  <Layout>
    <Seo title="Engajamento Social" />
    <SectionSocialEngagementIntern />
    <WppButtonFloat />
  </Layout>
)

export default SocialEngagementPage
